<template>
  <div id="payoutBalanceComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }}</b></CCardHeader
      >
      <CCardBody>
        <CDataTable
          hover
          striped
          border
          sorter
          :items="datas"
          :fields="fields"
          :items-per-page="25"
          :pagination="{ doubleArrows: false, align: 'center' }"
        >
          <td slot="from_to" slot-scope="{ item }">
            {{ item.from_to }}
          </td>
          <td slot="payout_day" slot-scope="{ item }">
            {{ item.payout_day }}
          </td>
          <td slot="total" slot-scope="{ item }">
            {{ item.total_amount | currency100 }}
          </td>
          <td slot="change" slot-scope="{ item }">
            {{ item.change_amount | currency100 }}
          </td>

          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
              >
                {{ Boolean(datas[index]._toggled) ? 'Hide' : 'Show' }}
              </CButton>
            </td>
          </template>
          <template #details="{ item, index }">
            <CCollapse
              :show="Boolean(datas[index]._toggled)"
              :duration="collapseDuration"
            >
              <SPPayoutBalanceComponent :items="item.payout_balance" />
            </CCollapse>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Common } from '@/constants';
import ProviderProxy from '@/proxies/provider.proxy';
import SPPayoutBalanceComponent from '@/components/SPPayoutBalanceComponent';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    balanceType: {
      type: String,
      required: true,
    },
  },
  components: {
    SPPayoutBalanceComponent,
  },
  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      datas: [],
      fields: [
        { key: 'from_to', label: 'From To' },
        { key: 'payout_day', label: 'Pay out date' },
        { key: 'total', label: 'Total' },
        { key: 'change', label: 'Change' },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false,
        },
      ],
      collapseDuration: 0,
    };
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      ProviderProxy.getPayoutBalance(this.balanceType).then(({ data }) => {
        this.datas = data;
      });
    },
    toggleDetails(item, index) {
      this.$set(this.datas[index], '_toggled', !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
};
</script>
