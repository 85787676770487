<template>
  <CCardBody>
    <CDataTable border :items="items" :fields="fields">
      <td slot="id" slot-scope="{ item }">
        <CLink :href="'#/sp/' + item.provider_id">
          {{ item.provider_id }}
        </CLink>
      </td>

      <td slot="amount" slot-scope="{ item }">
        {{ item.total_payout | currency100 }}
      </td>
    </CDataTable>
  </CCardBody>
</template>

<script>
import { Common } from '@/constants';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      fields: [
        {
          key: 'id',
          label: 'SP',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
      ],
    };
  },
  methods: {},
};
</script>
