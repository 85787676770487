<template>
  <PayoutBalanceComponent
    title="SPs Balance Outstanding"
    balanceType="outstanding"
  />
</template>

<script>
import PayoutBalanceComponent from '@/components/PayoutBalanceComponent';

export default {
  name: 'SpBalanceOutstanding',
  components: {
    PayoutBalanceComponent,
  },
};
</script>

<style></style>
